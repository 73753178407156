import Cookies from 'js-cookie';
import React, { lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

const Login = lazy(() => import("./Login"));
const Index = lazy(() => import('./Home/Index'))

const MainRoutes = createBrowserRouter([
    { path: '/login', element: <Login /> },
    { path: '/', element: <Navigate to="/home" /> },
    { path: '/home', element: <RequireAuth component={<Index />} /> }
]);

export default MainRoutes;


function RequireAuth({ component }) {
    const token = Cookies.get('acc_tkn');
    const [path, search] = window.location.href.split('?')
    let _path = `/login`
    if (search) _path += `?${search}`
    if ([undefined, null, ""].includes(token)) { return <Navigate to={_path} replace />; }
    return component;
}