import './App.css'
import { Suspense } from 'react';
import { EncryptStorage } from 'encrypt-storage'
import { RouterProvider } from 'react-router-dom'
import MainRoutes from './MainRoutes';

// storage
export const storage = new EncryptStorage("global_params", {
  storageType: 'localStorage', encAlgorithm: 'Rabbit'
})
// end

function App() {
  storage.setItem('USER', [{ id: 1, name: 'admin', password: 'admin123', point: 0 }])
  storage.setItem('ITEM', [
    { id: 1, code: 'I01', name: 'Barang 1', point: 10 },
    { id: 2, code: 'I02', name: 'Barang 2', point: 20 },
  ])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={MainRoutes} />
    </Suspense>
  )
}

export default App